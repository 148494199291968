import './Contact.css'


import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

const ContactForm = ({ opcoesAssunto, title }) => {
  const { t } = useTranslation()
  const [dadosFormulario, setDadosFormulario] = useState({
    nome: '',
    email: '',
    assunto: '',
    body: '',
  });
  const contactinfo = [
    { image: 'image/phonecontact.png', title: t("phone"), desc:t("phonenumber"), alt: "Telefone JogaJunto" },
    { image: 'image/mailcontact.png', title: 'E-mail', desc: 'instituto@jogajuntoinstituto.org', alt: "E-mail JogaJunto" },
    { image: 'image/locontact.png', title: t("address"), desc: 'R. Hungria, 574 - Cidade Jardim, São Paulo - SP, 01455-903', alt: "Endereço JogaJunto" },
  ]
  const [exibirCamposExtras, setExibirCamposExtras] = useState(false);
 
  
  const [showFront, setShowFront] = useState(true);

  const { nome, email, assunto, body } = dadosFormulario;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDadosFormulario({ ...dadosFormulario, [name]: value });
    // if (name === 'assunto' && value === 'Fazer uma doação') {
    //   setExibirCamposExtras(true);
    // } else {
    //   setExibirCamposExtras(false);
    // }

  };

  const handleChangeSelection = (event) => {
    const { name, value } = event.target;
    setDadosFormulario({ ...dadosFormulario, [name]: value });
    if (name === 'assunto' && value === 'Fazer uma doação') {
      setExibirCamposExtras(true);
    } else {
      setExibirCamposExtras(false);
    }


  }

  const handleButtonClick = () => {
    setShowFront(!showFront);
    // console.log(dadosFormulario.assunto)
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Aqui você pode fazer a chamada à API para enviar os dados
    // Utilize a função fetch, axios ou outra biblioteca de sua preferência
    // Passando os dados do formulário no corpo da requisição
    try {
      const response = await fetch('https://gbjsygz94l.execute-api.us-east-1.amazonaws.com/dev/sendMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dadosFormulario)
      });
      // console.log(dadosFormulario)
      if (response.ok) {
        // const data = await response.json();
        // Lógica para tratar a resposta da API
        // console.log(data);
        if (dadosFormulario.assunto === 'Fazer uma doação') {

          handleButtonClick();
          window.alert('Dados recebidos! Faça sua doação com o Pix ou com cartões de débito ou crédito');
        } else {

          window.alert('Dados recebidos!');
        }
      } else {
        throw new Error('Erro na requisição');
      }
    } catch (error) {
      // Lógica para tratar erros na requisição
      console.error(error);
    }
  };
  useEffect(() => {
    function handleMouseClick(event) {
      if (event.target.id === 'btn-donate') {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const id = params.get('donate');

        if (id === 'true') {

          setDadosFormulario({ ...dadosFormulario, assunto: 'Fazer uma doação' });
          setExibirCamposExtras(true);
        } else {

          setExibirCamposExtras(false);
        }

        
    }
  };
    document.addEventListener('click', handleMouseClick);

    return () => {
      document.removeEventListener('click', handleMouseClick);
    };
 
  }, []);

  const copyToCopyBoard = () => {
    const textoCopiado = '38.627.081/0001-27';
    navigator.clipboard.writeText(textoCopiado)
      .then(() => {
        window.alert('Texto copiado para a área de transferência!  ');
        // Faça algo após copiar para a área de transferência, se necessário
      })
      
  };



  return (
    <section id="Contato" className='contact'>
      <div className="contactform">
        <form onSubmit={handleSubmit}>

          <div>
            <h3>{title}</h3>
            <label htmlFor="nome">{t("name")}</label>
            <input type="text" id="nome" name="nome" value={nome} required={true} placeholder={t("name-placeholder")} onChange={handleInputChange} />
          </div>
          <div>
            <label htmlFor="email">{t("email")}</label>
            <input type="email" id="email" name="email" value={email} required={true} placeholder={t("email-placeholder")} onChange={handleInputChange} />
          </div>
          <div>
            <label htmlFor="assunto">{t("subject")}</label>
            <select id="assunto" name="assunto" value={assunto} required={true} onChange={handleChangeSelection}>
              <option value="">{t("subject-placeholder")}</option>
              {opcoesAssunto.map((opcao) => (
                <option key={opcao.index} value={opcao}>{opcao}</option>
              ))}
            </select>
          </div>
          {!exibirCamposExtras && (
            <div>
              <label htmlFor="mensagem">{t("message")}</label>
              <textarea id="mensagem" name="body" value={body} placeholder={t("message-placeholder")} onChange={handleInputChange} />
            </div>

          )}
          <button type="submit">
          {exibirCamposExtras && ( 
            <p>
             
              {t("submit-donate")}
            </p>
              )}
               {!exibirCamposExtras && (
              <p>
                  {t("submit")}

              </p>
            )}</button>
          

        </form>
      </div>
      <div className='contact-info'>
        <div className='info-contact-container'>
          {showFront ? (
            <ul>
              {contactinfo.map(item => (
                <li key={item.index}>
                  <img src={item.image} alt={item.alt}></img>
                  <div>
                    <h6>{item.title}</h6>
                    <p>{item.desc}</p>
                  </div>

                </li>))}
            </ul>
          ) : (
            <div className="contact-back-content">
              {/* Conteúdo de trás do cartão */}
              <h5>Formas <br></br> de doação</h5>
              <a href='https://www.paypal.com/donate/?hosted_button_id=ABNWG273YLYWU' target= "blank">

              <button className='btn-voluntary'> <img src='image/paypal_donate.png' alt="Botão de doação para redirecionamento ao Paypal"></img>   Doe com cartão</button>
              </a>
              <div className='pix-div'>
                <img src='image/doe_qr_code.png' alt='QR code da chave pix do Instituto Joga Junto' className='pix-qr-code'></img>
                <div>

                  <h6> PIX </h6>
                  <p> Escaneie o código QR ou utilize nossa chave PIX</p>
                  <p> 38.627.081/0001-27 <span onClick={copyToCopyBoard}><img src='/image/copy.png' alt="QR CODE"></img></span></p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='yellow-side-container'>
          <div className='yellow-side-bar'>

          </div>
        </div>

      </div>
    </section>

  );
};

export default ContactForm;
