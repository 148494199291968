import Dropdown from './Dropdown';
import './NavMenu.css'
import { useTranslation } from 'react-i18next';

const NavMenu = (props) => {
  

  const { t } = useTranslation()
 
  const dropitems = [
    { label: t("team"), link: '/team' },
    
];
      
    return (
      <nav className="navmenu">
        <ul>
          {props.items.map(item => (
            <li key={item.label}>
              <a href={item.link}>
                {item.label}
              </a>
              
            </li>
          ))}
          <li>
            <Dropdown name={t("transparency")} items={dropitems}/>

          </li>
        </ul>
      </nav>
    );
  };
  
  export default NavMenu;