import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageOptions = [
  {
    name: 'PT-BR',
    value: 'ptBR',
    flag: '/image/ptBR_flag.png',
  },
  {
    name: 'ENG-US',
    value: 'en',
    flag: '/image/en_flag.png',
  },
  {
    name: 'ESP-LATAM',
    value: 'es',
    flag: '/image/es_flag.png',
  },
];

export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'ptBR');
 

  useEffect(() => {
    if (selectedLanguage){

      languageSelection(selectedLanguage);
    }
    else{
      languageSelection('ptBR');
    }
    
  }, []);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const languageSelection = (language) => {
    i18n.changeLanguage(language);
  }
 
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.getAttribute('data-value');
    setSelectedLanguage(newLanguage);
    languageSelection(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
    console.log(newLanguage);
    console.log(localStorage.getItem('selectedLanguage'))
  };

  return (
    <div className="language-select" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button className="option-language">
        <img src={`/image/${selectedLanguage}_flag.png`} alt={selectedLanguage} />
      </button>
      {isOpen && (
        <ul>
          {LanguageOptions.map((language) => (
            <li key={language.value} className="option-language" data-value={language.value} onClick={handleLanguageChange}>
              <img src={language.flag} alt={language.name} data-value={language.value} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

