import AboutSection from '../components/About';
import Banner from '../components/Banner';
import Metodology from '../components/Metodology';
import Project from '../components/Projects';
import EsgSection from '../components/ESG';
import JoinUs from '../components/JoinUs';
import Partner from '../components/Partner';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import BannerModal from '../components/BannerModal';
import { useTranslation } from 'react-i18next';



const PageIndex = () => {
  const { t } = useTranslation()
  const opcoesAssunto = [
    t("contactsub-1"),
    t("contactsub-2"),
    t("contactsub-3"),
    t("contactsub-4"),
    t("contactsub-5"),
    t("contactsub-6"),
    t("contactsub-7"),
    ]
    
    return (
    <div>
      <BannerModal></BannerModal>
      <Banner></Banner>
      <AboutSection/>
      <Metodology/>
      <Project/>
      <EsgSection/>
      <JoinUs/>
      <Partner/>
      <ContactForm opcoesAssunto={opcoesAssunto} title={t("general-title-contact")}/>
      <Footer/>


    </div>
  );
}

export default PageIndex
