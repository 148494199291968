import './MenuHamburguer.css'

import React, { useState } from 'react';
import Dropdown from '../../NavMenu/Dropdown';

const MenuHamburguer = (props) => {
  const dropitems = [
    { label: 'Equipe', link: 'http://equipeijj.s3-website-sa-east-1.amazonaws.com/#/' },
    
  ];
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
      <button className="menu-icon" onClick={toggleMenu} aria-labelledby="Menu Mobile">
        <span></span>
        <span></span>
        <span></span>
      </button>
      {isOpen && (
        <div className="menu-overlay">
          <button className="close-button" onClick={closeMenu}>
            
          </button>
              {/* <img src="image/ODS-1.png"></img> */}
              
          <ul className="menu-items">
          
          {props.items.map(item => (
            <li key={item}>
              <a href={item.link} onClick={closeMenu}>
                {item.label}
              </a>
              
            </li>
          ))}
          <li>

          <Dropdown name="Transparência" items={dropitems}/>
          </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuHamburguer;
