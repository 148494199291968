import React, { useState, useEffect } from "react";
import "./Modal.css"; // Importa o CSS

const BannerModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Exibir o modal automaticamente ao carregar a página
    setIsVisible(true);
  }, []);

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="overlay">
        <div className="modal-popup">
          <button className="close-button" onClick={closeModal}>
            &times;
          </button>
          <a href="https://processoseletivo.jogajuntoinstituto.org/" target="_blank">

          <img src="/inscricao_banner.png" alt="Banner" className="banner-popup" />
          </a>
        </div>
      </div>
    )
  );
};

export default BannerModal;
